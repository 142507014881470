<div class="site-content">
  <section class="hero search-pattern is-primary">
    <div class="hero-body main-header">
      <div class="container">
        <div class="columns">
          <div class="column has-text-centered is-12">
            <a [routerLink]="['/']"><img src="../assets/images/logo-inverted-400.png" alt="NuGet Trends brand logo"></a>
          </div>
        </div>
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <div class="control has-icons-left has-icons-right">
              <app-search-input [trace]="'search-input'"></app-search-input>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-centered is-12">
          </div>
        </div>
      </div>
    </div>
  </section>
  <router-outlet></router-outlet>
</div>
<app-footer [trace]="'footer'"></app-footer>
<app-loading [trace]="'loading'"></app-loading>
