<div class="loading-screen-wrapper">
  <div class="loading-screen-icon">
      <div class="loadchart">
          <div class="loadchart__bar"></div>
          <div class="loadchart__bar"></div>
          <div class="loadchart__bar"></div>
          <div class="loadchart__bar"></div>
          <div class="loadchart__bar"></div>
          <div class="loadchart__ball"></div>
        </div>
  </div>
</div>
