<section class="hero is-white hero-space" [@slideInOut]>
  <div class="hero-body">
    <div class="container is-fluid">
      <div class="columns">
        <div class="column is-4">
          <nav class="level">
            <div class="level-item has-text-centered">
              <figure class='image is-96x96'><img src='../../assets/images/chart_icon.png'></figure>
            </div>
          </nav>
          <p class="title is-4 has-text-centered">Compare package download</p>
          <p class="subtitle is-5 has-text-centered">See the download history of NuGet Packages over time</p>
        </div>
        <div class="column is-4">
          <nav class="level">
            <div class="level-item has-text-centered">
              <figure class='image is-96x96'><img src='../../assets/images/target_icon.png'></figure>
            </div>
          </nav>
          <p class="title is-4 has-text-centered">Compare Target Framework Adoption</p>
          <p class="subtitle is-5 has-text-centered">Find out the amount of packages targeting a specific target
            framework</p>
        </div>
        <div class="column is-4">
          <nav class="level">
            <div class="level-item has-text-centered">
              <figure class='image is-96x96'><img src='../../assets/images/decision_icon.png'></figure>
            </div>
          </nav>
          <p class="title is-4 has-text-centered">Decide adoption based on facts</p>
          <p class="subtitle is-5 has-text-centered">Decide whether to use or not that package or target framework based on
            real data</p>
        </div>
      </div>
    </div>
  </div>
</section>
