<footer>
  <div class="container">
    <div class="columns">
      <div class="column has-text-centered">
        <a href="/">
          <img src="../../assets/images/logo-inverted-350.png" alt="NuGet Trends brand logo">
        </a>
      </div>
      <div class="column has-text-centered">
        <div class="footer-item-header">Connect</div>
        <ul>
          <li>
            <a href="https://twitter.com/NuGetTrends" class="footer-item" target="_blank">
              <span class="icon is-large">
                  <i class="fab fa fa-2x fa-twitter"></i>
              </span>Twitter
            </a>
          </li>
          <li>
            <a href="https://github.com/dotnet/nuget-trends" class="footer-item" target="_blank">
              <span class="icon is-large">
                  <i class="fab fa fa-2x fa-github"></i>
              </span>GitHub
            </a>
          </li>
        </ul>
      </div>
    </div>
    <p class="has-text-centered has-text-white-bis">
      Brought to you by <a href="https://github.com/bruno-garcia">Bruno Garcia</a> & <a href="https://github.com/joaopgrassi">João Grassi</a>
    </p>
    <p class="has-text-centered has-text-grey-light">
      <strong><a target="_blank" rel="Supported by" href="https://dotnetfoundation.org">.NET Foundation</a></strong>
    </p>
    <p class="has-text-centered has-text-grey-light">
      Data before 2018 gladly provided by <strong><a class="has-text-grey-light" target="_blank" rel="Data Partner"
      href="https://entityframework-extensions.net/">Entity Framework Extensions</a></strong>
    </p>
  </div>
</footer>
