<section>
  <div matAutocompleteOrigin
       #origin="matAutocompleteOrigin"
       class="control"
       [ngClass]="{'is-large is-loading' : isSearching}">
    <input
      class="input is-large is-shadowless"
      type="text"
      (focusout)="clear()"
      #searchBox
      placeholder="Start typing to discover NuGet Trends"
      [formControl]="queryField"
      [matAutocomplete]="auto"
      [matAutocompleteConnectedTo]="origin">
    <span class="icon is-medium is-left"><i class="fa fa-search"></i></span>
  </div>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)='searchItemSelected($event)'>
    <mat-option *ngFor="let package of results$ | async" [value]="package.packageId">
      <img class="package-img" src="{{package.iconUrl}}"
           onerror="this.onerror=null;this.src='https://nuget.org/Content/Images/packageDefaultIcon-50x50.png';"/>
      <span>{{package.packageId}}</span>
    </mat-option>
  </mat-autocomplete>
</section>
